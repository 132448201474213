<template>
  <div>
    <div class="row">
      <div class="col-md-8">
        <div class="row">
          <div class="col-md-12 gutter-b">
            <ReiseterminViewCardVorgaenge
              v-if="reiseterminObj"
              :reiseterminObj="reiseterminObj"
              tabname="default"
              @update-single-item="$emit('updated-vorgang', $event)"
            ></ReiseterminViewCardVorgaenge>
            <div v-if="!reiseterminObj" class="card card-custom">
              <div class="card-header">
                <b-skeleton class="m-auto" height="100%" width="100%"></b-skeleton>
              </div>
              <div class="card-body">
                <b-skeleton-table :rows="8" :columns="6"></b-skeleton-table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <template v-if="reiseterminObj">
          <ReiseterminCardCommentBase
            :doNotShowYieldComments="true"
            :doNotShowPmComments="true"
            :reisekuerzel="reiseterminObj.reise.reisekuerzel"
            :reiseterminId="reiseterminObj.id"
            :startdatum="reiseterminObj.startdatum"
            :initalComments="reiseterminObj.comments"
          ></ReiseterminCardCommentBase>
        </template>
        <template v-else>
          <div class="card">
            <div class="card-header">
              <b-skeleton class="m-auto" height="100%" width="100%"></b-skeleton>
            </div>
            <div class="card-body">
              <b-skeleton class="m-auto" height="200px" width="100%"></b-skeleton>
            </div>
          </div>
        </template>
      </div>

      <div class="col-md-12 gutter-b">
        <template v-if="reiseterminObj">
          <div>
            <ReiseterminCardVorgaengeDetails
              :defaultTab="2"
              :reiseterminObj="reiseterminObj"
              :isReiseterminInReleasePruefung="isReiseterminInReleasePruefung || isInTransferplanung"
              :isInTransferplanung="isInTransferplanung"
              :gueltigeKonfiguration="reiseterminObj.gueltigeKonfiguration"
            ></ReiseterminCardVorgaengeDetails>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.tox-tinymce {
  height: 200px !important;
}
.more-btn {
  cursor: pointer;
}
</style>

<script>
import ReiseterminCardCommentBase from '@/components/produkte/reisetermine/reisetermin-card-comment-base.vue';
import ReiseterminCardVorgaengeDetails from '@/components/produkte/reisetermine/reisetermin-card-vorgaenge-details.vue';
import ReiseterminViewCardVorgaenge from '@/components/produkte/reisetermine/reisetermin-card-vorgaenge.vue';

export default {
  name: 'ReisetermineViewComponent',
  components: {
    ReiseterminCardVorgaengeDetails,
    ReiseterminCardCommentBase,
    ReiseterminViewCardVorgaenge,
  },
  props: {
    reiseterminObj: Object,
  },
  data() {
    return {};
  },
  computed: {
    isReiseterminInReleasePruefung() {
      return this.reiseterminObj.state === 'Aufgelegt';
    },
    isInTransferplanung() {
      return this.reiseterminObj.state === 'Aufgelegt';
    },
  },
  methods: {},
};
</script>
