<template>
  <VorgangTable
    v-if="vorgaenge"
    :showingFlights="true"
    :displayFieldsByKey="displayedFieldsByKey"
    releaseprocessActions1Label="Teilnehmernamen"
    :isLoading="isLoading"
    :items="vorgaenge"
    :isOutlined="true"
    :dokumenteCheckRequired="dokumenteCheckRequired"
    :passdatenCheckRequired="passdatenCheckRequired"
  >
    <template #thWertSlot="{ item }">
      <template v-if="!getThwerteAtBuchungsdatumByVorgangIdisLoading(item.id)">
        {{ getThwerteAtBuchungsdatumByVorgangId(item.id, 'thWertGesamt') }}
      </template>
      <b-spinner v-else small></b-spinner>
    </template>
    <template v-slot:vorgangDetails="data">
      <b-button
        @click="onClickFlugChanged($event, data)"
        size="sm"
        variant="warning"
        class="text-white m-1"
        :id="'vorgang' + data.vorgangId"
        v-if="!data.isFlightsEqual"
      >
        Flüge geändert
        <i class="flaticon2-exclamation text-white"></i>
      </b-button>
      <b-tooltip v-if="!data.isFlightsEqual" :target="'vorgang' + data.vorgangId">
        <div
          class="mb-3"
          v-for="item in flightChanges(data.vorgangId)"
          :key="'flightchange' + item.flugstrecke + '-' + data.vorgangId"
        >
          <div v-for="(value, key) in item" :key="key + item.flugstrecke">
            <b v-if="key !== 'fluganzahl'" class="text-capitalize">{{ key }}:</b>
            <br />{{ value }}
          </div>
        </div>
      </b-tooltip>
      <div
        :class="[!data.isFlightsEqual ? 'bg-warning-o-30' : 'bg-gray-100']"
        class="border-bottom border-dark"
      >
        <b-table
          small
          fixed
          table-class="table table-standard mb-0"
          thead-tr-class="th-no-padding-top"
          no-local-sorting
          id="vorgaengeDetailsTableFluege"
          v-if="data.fluege && data.fluege.length > 0"
          :fields="['Flugstrecke', 'flugstamm', 'flugnummer', 'abflugdatum', 'abflugzeit', 'ankunftzeit']"
          :items="data.fluege"
        >
          <template #cell(Flugstrecke)="row">
            {{
              row.item.teilnehmerFluegeMatchNumbers
                ? `(${row.item.teilnehmerFluegeMatchNumbers.join(',')}) `
                : null
            }}
            {{ row.item.abflughafenEigen ? row.item.abflughafenEigen : row.item.abflughafen }} -
            {{
              row.item.ankunftsflughafenEigen ? row.item.ankunftsflughafenEigen : row.item.ankunftsflughafen
            }}
          </template>
          <template #cell(flugnummer)="row"> {{ row.item.flugnummer }}</template>

          <template #cell(abflugdatum)="row"> {{ row.item.abflugdatum | date }}</template>
          <template #cell(abflugzeit)="row">
            {{
              row.item.abflugsZeit
                ? row.item.abflugsZeit + ' Uhr'
                : $options.filters.time(row.item.abflugdatum)
            }}</template
          >
          <template #cell(ankunftzeit)="row">
            {{
              row.item.ankunftsZeit
                ? row.item.ankunftsZeit + ' Uhr'
                : $options.filters.time(row.item.ankunftsdatum)
            }}
            <span v-if="row.item.ankunftsdatum && row.item.abflugdatum">
              {{ isArrivalTomorrow(row.item) ? ' +1' : '' }}
            </span>
          </template>
        </b-table>
      </div>
    </template>
  </VorgangTable>
</template>

<script>
import VorgangTable from '@/components/vorgaenge/vorgang-table.vue';
import { format, parseISO, isSameDay } from 'date-fns';

export default {
  components: { VorgangTable },
  props: {
    thwerteAtBuchungsdatumByVorgangId: { type: Object },
    isLoading: { type: Boolean },
    vorgaenge: { type: Array },
    passdatenCheckRequired: { type: Boolean },
    dokumenteCheckRequired: { type: Boolean },
    isGarantiertTableDetails: { type: Boolean, default: false },
  },
  computed: {
    displayedFieldsByKey() {
      if (this.isGarantiertTableDetails) {
        return [
          'id',
          'pnr',
          'teilnehmercell',
          this.thwerteAtBuchungsdatumByVorgangId ? 'thWert' : null,
          'flugticketsAusgestellt',
          'ticketingTimelimit',
          'npx',
        ];
      } else {
        return [
          'id',
          'pnr',
          'airlinePNR',
          'reisezeitraum',
          'kunde',
          'teilnehmercell',
          this.thwerteAtBuchungsdatumByVorgangId ? 'thWert' : null,
          'flugticketsAusgestellt',
          'ticketingTimelimit',
          'npx',
        ];
      }
    },
  },
  methods: {
    isArrivalTomorrow(item) {
      const departure = parseISO(item.abflugdatum);
      const arrival = parseISO(item.ankunftsdatum);
      return !isSameDay(arrival, departure);
    },
    async onClickFlugChanged($event, flugData) {
      const msgBoxConfirm = await this.$bvModal.msgBoxConfirm('Änderungen der Flüge zur Kenntnis genommen?', {
        centered: true,
        okTitle: 'OK',
        cancelTitle: 'Abbrechen',
      });

      if (msgBoxConfirm) {
        this.$emit('fluege-change-accepted', { ...$event, flugData: flugData });
      }
    },
    getThwerteAtBuchungsdatumByVorgangId(vorgangId, key) {
      const vorgangData = this.thwerteAtBuchungsdatumByVorgangId?.[vorgangId]?.data;
      if (vorgangData && vorgangData[key]) {
        return this.$options.filters.currency(vorgangData[key]);
      }
      return '-';
    },
    getThwerteAtBuchungsdatumByVorgangIdisLoading(vorgangId) {
      return this.thwerteAtBuchungsdatumByVorgangId?.[vorgangId]?.isLoading;
    },
    flightChanges(vorgangId) {
      const allFlightsOfVorgang = this.vorgaenge.find(vorgang => vorgang.id === vorgangId)?.allFlights;
      const flightsNew = allFlightsOfVorgang.fluege;
      const flightsOld = allFlightsOfVorgang.fluegeAfterBuchungReleased;
      const keysToCompare = [
        'flugstamm',
        'abflughafen',
        'ankunftsflughafen',
        'flugart',
        'abflugdatum',
        'ankunftsdatum',
      ];
      let resultText;
      const differencesInSameFlightNumbers = flightsNew
        .map(flightNew => {
          const sameFlight = flightsOld.find(flightOld => flightOld.flugnummer === flightNew.flugnummer);
          if (sameFlight) {
            const differences = {
              flugstrecke: flightNew.abflughafen + ' - ' + flightNew.ankunftsflughafen,
            };
            if (flightNew.abflughafen === null || flightNew.ankunftsflughafen === null) return null;
            for (const key of keysToCompare) {
              if (flightNew[key] !== sameFlight[key]) {
                if (key.includes('datum')) {
                  differences[key] =
                    (sameFlight[key] === null ? '-' : format(new Date(sameFlight[key]), 'dd.MM HH:mm')) +
                    ' -> ' +
                    (flightNew[key] === null ? '-' : format(new Date(flightNew[key]), 'dd.MM HH:mm'));
                } else differences[key] = sameFlight[key] + ' -> ' + flightNew[key];
              }
            }
            return differences;
          } else return null;
        })
        .filter(differenceObject => differenceObject && Object.keys(differenceObject).length > 1);

      if (flightsNew.length < flightsOld.length) {
        differencesInSameFlightNumbers.push({
          fluganzahl:
            'Die Fluganzahl hat sich von ' + flightsOld.length + ' auf ' + flightsNew.length + ' geändert.',
        });
      } else if (flightsNew.length > flightsOld.length) {
        differencesInSameFlightNumbers.push({
          fluganzahl:
            'Die Fluganzahl hat sich von ' + flightsOld.length + ' auf ' + flightsNew.length + ' geändert.',
        });
      }
      return differencesInSameFlightNumbers;
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.passdaten-col) {
  width: 50rem;
}
:deep(.th-no-padding-top > th) {
  padding-top: 0px;
}
:deep(.th-no-padding-top > th) {
  border-bottom: 0px;
}
:deep(.th-no-padding-top > th > div) {
  color: #b5b5c3;
}
:deep(.custom-tooltip) {
  max-width: 800px !important;
  width: 700px !important;
}
.tooltip.show {
  opacity: 1 !important;
}
</style>
