var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card card-custom"},[_c('div',{staticClass:"card-header card-header-tabs-line"},[_c('div',{staticClass:"card-title"},[_c('h3',{staticClass:"card-label"},[_vm._v("Vorgangdetails")]),(_vm.activeTabFromRouter === 'teilnehmer')?_c('div',{staticClass:"d-flex flex-row font-size-sm ml-6"},[(!_vm.isLoading)?[_c('b-dropdown',{attrs:{"right":"","text":"Download Teilnehmerliste","size":"sm","variant":"success"}},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.downloadArrayAsExcel('de')}}},[_c('span',{staticClass:"symbol symbol-20 mr-3"},[_c('img',{attrs:{"src":"media/svg/flags/162-germany.svg","alt":""}})]),_vm._v(" Deutsch ")]),_c('b-dropdown-item',{on:{"click":function($event){return _vm.downloadArrayAsExcel('en')}}},[_c('span',{staticClass:"symbol symbol-20 mr-3"},[_c('img',{attrs:{"src":"media/svg/flags/226-united-states.svg","alt":""}})]),_vm._v(" Englisch ")])],1)]:_c('b-spinner')],2):_vm._e(),(_vm.activeTabFromRouter === 'fluege')?_c('div',{staticClass:"d-flex flex-row font-size-sm ml-6"},[(!_vm.isLoading)?[_c('b-dropdown',{attrs:{"right":"","text":"Download Flugliste","size":"sm","variant":"success"}},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.downloadFluglisteAsExcel('de')}}},[_c('span',{staticClass:"symbol symbol-20 mr-3"},[_c('img',{attrs:{"src":"media/svg/flags/162-germany.svg","alt":""}})]),_vm._v(" Deutsch ")]),_c('b-dropdown-item',{on:{"click":function($event){return _vm.downloadFluglisteAsExcel('en')}}},[_c('span',{staticClass:"symbol symbol-20 mr-3"},[_c('img',{attrs:{"src":"media/svg/flags/226-united-states.svg","alt":""}})]),_vm._v(" Englisch ")])],1)]:_c('b-spinner')],2):_vm._e()]),_c('div',{staticClass:"card-toolbar"},[_c('ul',{staticClass:"nav nav-tabs nav-bold nav-tabs-line"},[_c('li',{staticClass:"nav-item"},[_c('router-link',{attrs:{"to":{
              path: '',
              query: { ...this.$route.query, vorgangdetailstab: 'teilnehmer' },
            }},scopedSlots:_vm._u([{key:"default",fn:function({ href }){return [_c('a',{staticClass:"nav-link",class:{ active: _vm.activeTabFromRouter === 'teilnehmer' },attrs:{"href":href}},[_c('span',{staticClass:"nav-icon"},[_c('i',{staticClass:"fas fa-user-check"})]),_c('span',{staticClass:"nav-text"},[_vm._v("Teilnehmerdaten")])])]}}])})],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{attrs:{"to":{
              path: '',
              query: { ...this.$route.query, vorgangdetailstab: 'fluege' },
            }},scopedSlots:_vm._u([{key:"default",fn:function({ href }){return [_c('a',{staticClass:"nav-link",class:{ active: _vm.activeTabFromRouter === 'fluege' },attrs:{"href":href}},[_c('span',{staticClass:"nav-icon"},[_c('i',{staticClass:"fas fa-plane"})]),_c('span',{staticClass:"nav-text"},[_vm._v("Flüge")])])]}}])})],1)])])]),_c('div',{staticClass:"card-body"},[(!_vm.isLoading)?_c('div',{staticClass:"tab-content"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeTabFromRouter === 'teilnehmer'),expression:"activeTabFromRouter === 'teilnehmer'"}],staticClass:"tab-pane active",class:{ show: _vm.activeTabFromRouter === 'teilnehmer' }},[(_vm.vorgaenge)?_c('ReiseterminCardVorgaengeTeilnehmer',{attrs:{"reisetermin":_vm.reiseterminObj,"konfiguration":_vm.gueltigeKonfiguration,"passdatenCheckRequired":_vm.passdatenCheckRequired,"dokumenteCheckRequired":_vm.dokumenteCheckRequired,"vorgaenge":_vm.filteredVorgaenge}}):_vm._e()],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeTabFromRouter === 'fluege'),expression:"activeTabFromRouter === 'fluege'"}],staticClass:"tab-pane active",class:{ show: _vm.activeTabFromRouter === 'fluege' }},[(_vm.vorgaenge)?_c('ReiseterminCardVorgaengeFluege',{attrs:{"passdatenCheckRequired":_vm.passdatenCheckRequired,"dokumenteCheckRequired":_vm.dokumenteCheckRequired,"vorgaenge":_vm.filteredVorgaenge},on:{"fluege-change-accepted":function($event){return _vm.acceptFluegeChanged($event)}}}):_vm._e()],1)]):_vm._e(),(_vm.isLoading)?_c('div',{staticClass:"tab-content"},[_c('b-skeleton-table',{attrs:{"rows":5,"columns":6}})],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }